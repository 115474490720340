<large-page-layout title="{{'header.translations' | translate}}" icon="fa-language">
<div class="col-md-12">
  <language (onChange)="onLangsChange($event)"></language>
  <div class="panel panel-shadow panel-success mb-4">
    <div class="panel-heading collapsible-pane-header opened">
        <span>
          <span class="ml-1">{{ 'header.translations' | translate }}</span>
        </span>
    </div>
  <input class="form-control list-search-input" type="text" placeholder="{{ 'Search for translations' | translate }}" [(ngModel)]="searchTerm" (keyup)="translationKeysBySearch()">
  <table class="table table-striped table-hover align-middle table-cols-equal-width" *ngIf="this.translations">
    <thead>
      <tr>
        <th>{{ 'translation-key' | translate }}</th>
        <th *ngFor="let lang of this.languages">{{lang}}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let key of searchKeys">
          <td>{{ key }}</td>
          <td *ngFor="let lang of this.languages">
            <textarea rows="1" class="form-control" [(ngModel)]="this.translations[lang] && this.translations[lang][key]" (blur)="onTranslationBlur(lang, key)" ></textarea></td>
      </tr>
    </tbody>
  </table>
  </div>
</div>
</large-page-layout>