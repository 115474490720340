<div class="dashboard-tile f-container f-stack-vertical text-center card">
	<a [routerLink]=link>
		<div class="f-centered-container">
			<h2>
				<span class="fa-stack fa-lg">
					<i class="fa fa-circle fa-stack-2x icon-background-color"></i>
					<i class="fa {{icon}} fa-stack-1x"></i>
				</span>
			</h2>
		</div>
		<div class="card-block">
			<h4 style="font-weight: 400" class="card-title">{{title | translate}}</h4>
			<p class="card-text">{{description ? '' : ' &nbsp;'}}{{description | translate}}</p>
			<!-- <a [routerLink]=link class="btn btn-w-md btn-line-info">{{buttonText | translate}}</a> -->
		</div>
	</a>
</div>
