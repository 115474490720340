<form-page title="{{'general.create' | translate}} {{'users.user' | translate}}"
           [errors]="errors"
           [successes]="successes"
           [save]="save.bind(this)"
           icon="fa-plus">

	<strong>{{errorMessage}}</strong>

	<div class="row">
		<div *ngFor="let userProp of userProps" class="col-md-6">
				<label-input-pair label="{{ userProp.label }}"
				disabled="{{ userProp.binary ? 'true' : 'false' }}"
				required="{{ userProp.requiredOnForm ? 'true' : 'false' }}"
				pattern="{{ userProp.display?.pattern }}"
				patternErrorMessage="{{ userProp.getPatternErrorMessage(translate.currentLang) }}"
				class=""
				[(model)]="userProp.value"></label-input-pair>
		</div>
	</div>

</form-page>