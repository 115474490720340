<large-page-layout title="{{'dashboard.manage_users' | translate}}" icon="fa-users" goBackLink="/dashboard">

	<div class="list-nav">
		<!--<span class="fa-stack fa-lg">
			<i class="fa fa-circle fa-stack-2x icon-background-color"></i>
			<i class="fa fa-users fa-stack-1x"></i>
		</span>
		<h1>{{'dashboard.manage_users' | translate}}</h1>-->
		<input style="max-width: 300px; padding-left: 10px; min-width: 200px; margin-left: 10px;"
			[attr.value]="params.searchTerm"
			(input)="setParam('searchTerm', $event.target.value);"
			class="form-control"
			placeholder="{{ 'Type to search' | translate }}"
			/>
		<div *ngIf="totalResultItemsCount > params.itemsOnPage">
			<ul style="list-style: none;">
				<li *ngFor="let item of pages | paginate: { itemsPerPage: params.itemsOnPage, currentPage: params.nrPage, totalItems: totalResultItemsCount }"></li>
			</ul>
			<pagination-controls class="app-pagination"
				previousLabel="{{ 'general.previous_page' | translate }}"
				nextLabel="{{ 'general.next_page' | translate }}"
				(pageChange)="setParam('nrPage', $event)"></pagination-controls>
		</div>
		<app-upload-users style="max-width: 300px; padding-left: 10px; min-width: 200px; margin-left: 10px;"></app-upload-users>
		
	</div>

	<a class="btn btn-line-success mb-1" [routerLink]="'/user/create'">
		<i class="fa fa-plus" ></i> {{ "general.add" | translate }}
	</a>

    <table class="table table-hover">
        <thead>
			<th *ngFor="let prop of propsToReturn">{{ prop.label }}</th>
		</thead>
		<tbody *ngIf="currentState==='loading'">
			<tr>
				<td [attr.colspan]="propsToReturn.length" style="text-align: center;">
					<i style="color: #000000;" class="fa fa-spinner"></i>
				</td>
			</tr>
		</tbody>
        <tbody *ngIf="currentState==='loaded'">
			<tr *ngIf="!users.length">
				<td [attr.colspan]="propsToReturn.length" style="text-align: center;">
					{{ "general.no_results" | translate }}
				</td>
			</tr>
			<tr *ngFor="let user of users" [routerLink]="'/user/'+user.id+'/edit'">
				<td *ngFor="let value of user.values; let i = index">
					<span *ngIf="i === 0 && user.userVerified">
						<i style="color: #00CF9E;" class="fa fa-shield"></i>
					</span>
					<span *ngIf="i === 0 && !user.userVerified">
						<i style="color: #CBD2D5;" class="fa fa-shield"></i>
					</span>
					{{value}}
				</td>
				<td style="width: 1px; text-align: center;">
					<button class="btn btn-empty text-danger" (click)="reasonForDeletion='';userToDelete=user.id;lgModal.show();$event.stopPropagation();"> 
						<i class="fa fa-trash"></i>
					</button>
				</td>
			</tr>
			<!-- </template> -->
		</tbody>
		<tbody *ngIf="currentState==='initial'">
			<tr>
				<td [attr.colspan]="propsToReturn.length" style="text-align: center;">
					{{ 'users.list_initial_info' | translate:{minCharactersToStartSearch: minCharactersToStartSearch} }}
				</td>
			</tr>
		</tbody>
	</table>
	

</large-page-layout>


<div bsModal #lgModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="lgModal.hide()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <h4 class="modal-title">{{'confirm.areyousure' | translate}}</h4>
            </div>
            <div class="modal-body">
				<div class="form-group">
					<label for="comment">{{'confirm.reasonfordeletion' | translate}}</label>
					<textarea class="form-control" rows="5" id="comment" [(ngModel)]="reasonForDeletion">
						{{reasonForDeletion}}
					</textarea>
				</div>
                <div class="f-row">
                    <button class="btn btn-w-md btn-line-success" (click)="lgModal.hide()">
                        <i class="fa fa-undo"></i>
                        {{'general.cancel' | translate}}
                    </button>
                    &nbsp;
                    <button [attr.disabled]="reasonForDeletion === '' ? true : null" class="btn btn-w-md btn-line-danger"
							(click)="delete(userToDelete);lgModal.hide()">
                        <i class="fa fa-trash"></i> {{'general.delete' | translate}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>