<form-page-layout title="{{'general.edit' | translate}} {{oaclient.id}}"
                  [errors]="errors"
                  [successes]="successes"
                  icon="fa-pencil">
  <form (ngSubmit)="save()">

    <strong>{{errorMessage}}</strong>

    <!-- label-input-pair label="oaclient.id"
                      required
                      icon="<i class='fa fa-laptop'></i>"
                      (model)="oaclient.id"></label-input-pair -->
    <div class="row">

      <label-input-pair label="oaclient.redirect_uri"
                        required
                        class="col-md-6"
                        [(model)]="oaclient.redirect_uri"></label-input-pair>

      <label-select-pair label="Disabled"
                        [options]="[{value:true,name:'Yes'},{value:false,name:'No'}]"
                        class="col-md-6"
                        [(model)]="oaclient.disabled"></label-select-pair>

      <label-select-pair label="oaclient.trusted"
                        [options]="[{value:true,name:'Yes'},{value:false,name:'No'}]"
                        class="col-md-6"
              [(model)]="oaclient.trusted"></label-select-pair>

    <label-input-pair label="oaclient.landing_page_uri" class="col-md-6"
              [(model)]="oaclient.landing_page_uri"></label-input-pair>

    <app-file-upload
      [label]="logoLabel"
      [imageUrls]="logoImageUrls"
      [deleteUrl]="logoSaveUrl"
      [saveUrl]="logoSaveUrl"
      (onChange)="onLogoChange($event)"
      class="col-md-6"
      >
    </app-file-upload>

      <label-input-pair label="Description" class="col-md-6"
                        [(model)]="oaclient.description"></label-input-pair>
                      
    </div>




    <form-page-button-block></form-page-button-block>
  </form>
</form-page-layout>