<div class="main-bg">
	<div class="home-page-container">
		<div class="translations-panel">
			<div *ngFor="let lang of translate.getLangs(); let index = index;">
				<a class="lang-link {{lang === translate.currentLang ? 'lang-link--active' : ''}}" href="#" (click)="userLangResolver.changeLang(lang); false;">{{ lang | translate }}</a>
				<span *ngIf="translate.getLangs().length - 1 > index"></span>
			</div>
		</div>
		<header>
			<img [src]="casBaseUrl + 'configuration/settings/cas-logo'"/>
		</header>
		<main>
			<a href="{{authUrl}}cas/spa.html#/?response_type=code&client_id={{OAuthClientId}}&state={{randomHash}}&scope=any&redirect_uri={{OAuthRedirectUrl}}&lang={{translate.currentLang}}"
			 type="submit" class="btn button-primary btn-lg btn-block">{{ 'Sign in or sign up for account' | translate }}</a>
		</main>
		<footer>
			<h1>{{ 'homepage.maininfo' | translate }}</h1>
			<p>{{ 'homepage.description' | translate }}</p>
			<div class="partners">
				<span *ngFor="let partner of partners; let index = index;">
					<div style="padding-right: 30px;">
						<a href="//{{partner.redirect_url}}">
							<img height="{{partner.logo_height}}" width="{{partner.logo_width}}" src="{{partner.base64}}" alt="{{partner.name}}" />
						</a>
					</div>
				</span>
			</div>
		</footer>
	</div>
</div>
