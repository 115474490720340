<div class="form-group">
    <label class="label-for-pair {{large ? 'label-for-pair--large' : '' }}" for="{{label}}">
        <span style="font-weight:400" class="text-danger">{{required ? '* ' : '' }}</span>
        {{label | translate}} <span [innerHTML]="additionalInfo"></span>
        <span *ngIf="verified"><i class="fa fa-shield verified-field-icon"></i></span>
    </label>
    <button class="re-verify-button" *ngIf="reVerifyShow" (click)="reVerifyClick($event)" type="button">{{"Validate" | translate}}</button>
    <div style="width:100%" class="input-group {{large ? 'input-group-lg ' : '' }}">
        <span *ngIf="icon" class="input-group-addon" [innerHTML]="icon"></span>
        <input [attr.type]="type ? type : null"
               [(ngModel)]="model"
               (ngModelChange)="onChanges($event)"
               (focus)="onFocus($event)"
               [attr.required]="required ? true : null"
               [attr.disabled]="disabled ? true : null"
               class="form-control {{invalid ? 'input--errored' : '' }}"
               name="{{label}}"
               id="{{label}}"
               pattern="{{pattern}}"
               placeholder="{{placeholder ? placeholder: label | translate}}">
    </div>
	<span ng-show="invalid && errorMessage" style="color: red">{{ errorMessage | translate }}</span>
    {{helpMessage | translate}}
</div>
