<div class="form-group">
	<label class="label-for-pair" style="font-weight: inherit;">
		{{ label | translate }} 
		<span *ngIf="verified"><i class="fa fa-shield verified-field-icon"></i></span>
	</label>
	<div class="drag-n-drop-container" ngFileDrop [options]="logoUploadOptions" [ngClass]="{'file-over': hasBaseDropZoneOverLogo}">
		<label class="upload-button file-input-wrapper btn btn-default form-control">
			<input type="file" class="hidden"
				ngFileSelect [options]="logoUploadOptions" (uploadOutput)="onUploadOutput($event)" accept="{{allowedFileTypes}}">
			<i class="glyphicon glyphicon-cloud-upload"></i>
			{{ 'general.file_upload' | translate }}
		</label>
	</div>
	<button type="button" style="margin: 10px 0;" *ngIf="imageSrcs.length" class="btn btn-w-md btn-line-danger" (click)="lgModal.show()" >{{ 'general.delete_files' | translate }}</button>
	<button type="button" style="margin: 10px 0;" *ngIf="base64mode && base64.value !== ''" class="btn btn-w-md btn-line-danger" (click)="lgModal.show()" >{{ 'general.delete_file' | translate }}</button>
	<div *ngIf="isUploading">
		<i style="color: #000000;" class="fa fa-spinner fa-spin"></i>
	</div>
	<div *ngIf="imageSrcs.length">
		<div *ngFor="let imageSrc of imageSrcs; let i = index">
			<a *ngIf="isForDownload" [attr.href]="imageSrc" [attr.download]="'download - ' + (i + 1)">{{ 'general.download' | translate }} - {{i + 1}}</a>
			<img *ngIf="!isForDownload" style="max-width: 250px;" [src]="imageSrc">
		</div>
	</div>
	<div *ngIf="base64mode">
		<div *ngIf="base64.value !== ''">
			<img style="max-width: 250px;" [src]="base64.value">
		</div>
	</div>
</div>

<div bsModal #lgModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-sm">
		<div class="modal-content">
			<div class="modal-header">
				<button type="button" class="close" (click)="lgModal.hide()" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
				<h4 class="modal-title">{{'confirm.areyousure' | translate}}</h4>
			</div>
			<div class="modal-body">
				<div class="f-row">
					<button class="btn btn-w-md btn-line-success" (click)="lgModal.hide()">
						<i class="fa fa-undo"></i>
						{{'general.cancel' | translate}}
					</button>
					&nbsp;
					<button *ngIf="imageSrcs.length" class="btn btn-w-md btn-line-danger" (click)="deleteImages();lgModal.hide()">
						<i class="fa fa-trash"></i> {{'general.delete' | translate}}
					</button>
					<button *ngIf="base64mode && base64.value !== ''" class="btn btn-w-md btn-line-danger" (click)="deleteBase64();lgModal.hide()">
						<i class="fa fa-trash"></i> {{'general.delete' | translate}}
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
