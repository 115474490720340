<div bsModal #lgModal class="modal" [ngClass]="{'in': _open}" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
	 aria-hidden="true">
	<div class="modal-dialog" [ngClass]="{'modal-sm': size === 'small'}">
		<div class="modal-content">
			<div class="modal-header">
				<button type="button" class="close" (click)="closeModal()" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
				<h4 class="modal-title {{ 'text-' + type }}"><span *ngIf="type === 'warning'">⚠ </span>{{ heading | translate }}</h4>
			</div>
			<div class="modal-body">
				<ng-content></ng-content>
			</div>
		</div>
	</div>
</div>
