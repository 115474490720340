
<form-page-layout title="{{'general.edit' | translate}} {{ propertyName | translate }}"
                  [errors]="errors"
                  [successes]="successes"
                  icon="fa-pencil">
  <form (ngSubmit)="save()">

    <strong>{{errorMessage}}</strong>
    <div class="row">
    <!-- label-input-pair label="oaclient.id"
                      required
                      icon="<i class='fa fa-laptop'></i>"
					  (model)="oaclient.id"></label-input-pair -->
	<label-select-pair class="col-md-6" label="{{'configure_registration_form.usedOnForm' | translate}}" [options]="[{value:true,name:'Yes' | translate},{value:false,name:'No' | translate}]" [(model)]="formFieldMetaPropeties.usedOnForm"></label-select-pair>
	<label-input-pair class="col-md-6" disabled="{{ formFieldMetaPropeties.name === 'password' ? 'true' : 'false' }}" label="{{'configure_registration_form.name' | translate}}" [(model)]="formFieldMetaPropeties.name"></label-input-pair>
	<label-select-pair class="col-md-6" label="{{'configure_registration_form.requiredOnForm' | translate}}" [options]="[{value:true,name:'Yes' | translate},{value:false,name:'No' | translate}]" [(model)]="formFieldMetaPropeties.requiredOnForm"></label-select-pair>
	<label-select-pair class="col-md-6" label="{{'configure_registration_form.partOfVerifiedUser' | translate}}"
					   [options]="[{value:true,name:'Yes' | translate},{value:false,name:'No' | translate}]"
					   [(model)]="formFieldMetaPropeties.part_of_verified_user">
	</label-select-pair>
	<label-select-pair class="col-md-6" label="{{'configure_registration_form.invalidatingProperties' | translate}}"
					   *ngIf="formFieldMetaPropeties.name !== 'password'"
					   [multiple]="true"
					   [multiSelectOptions]="invalidating_properties_options"
					   [(model)]="formFieldMetaPropeties.invalidating_properties">
	</label-select-pair>
	<label-select-pair class="col-md-6" *ngIf="formFieldMetaPropeties.name !== 'password'" label="{{'configure_registration_form.type' | translate}}" [options]="fieldTypes" [(model)]="formFieldMetaPropeties.display.type"></label-select-pair>
	<ng-template ngFor let-item [ngForOf]="translate.getLangs()">
			<label-input-pair class="col-md-6" label="{{'configure_registration_form.tooltipMessage_' + item | translate}}" [(model)]="formFieldMetaPropeties.display.tooltipMessage[item]"></label-input-pair>
	</ng-template>
	<label-input-pair class="col-md-6" disabled="{{ formFieldMetaPropeties.name === 'password' || !['text', 'email'].includes(formFieldMetaPropeties.display.type) ? 'true' : 'false' }}" title="{{ 'configure_registration_form.inputMaskTooltip' | translate }}" label="{{'configure_registration_form.inputMask' | translate}}" [(model)]="formFieldMetaPropeties.display.inputMask"></label-input-pair>
	<ng-template ngFor let-item [ngForOf]="translate.getLangs()">
			<label-input-pair class="col-md-6" label="{{'configure_registration_form.placeholder_' + item | translate}}" [(model)]="formFieldMetaPropeties.display.placeholder[item]"></label-input-pair>
  </ng-template>
  </div>

    <form-page-button-block></form-page-button-block>
  </form>
</form-page-layout>
