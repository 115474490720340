<form-page title="{{'users.user' | translate}} {{user.username}}"
           [errors]="errors"
           [successes]="successes"
           icon="fa-pencil"
		   *ngIf="user"
		   [save]="save.bind(this)">

	<h2 *ngIf="user.username">{{user.username}}</h2>

	<div class="row">

		<div class="col-md-12 mb-1" *ngIf="!user.verified">
			<span>
				<i style="color: #00CF9E;" class="fa fa-shield"></i>
			</span>
			&nbsp;{{ 'general.strong_verification' | translate }}
		</div>
		<div class="col-md-12">
			<div class="alert alert-info">{{ user.message }}</div>
		</div>

		<div *ngFor="let userProp of user.properties" class="col-md-6">
				<app-file-upload *ngIf="userProp.binary"
						[isForDownload]="true"
						[label]="userProp.label"
						[imageUrls]="userProp.imageUrls"
						[saveUrl]="userProp.saveUrl"
						[deleteUrl]="userProp.deleteUrl"
						(onChange)="onFileListChange($event)"
						[verified]="userProp.verified"
						></app-file-upload>
				<label-select-pair *ngIf="userProp.display?.type === 'checkbox'"
				label="{{ userProp.label }}"
				required="{{ userProp.requiredOnForm }}"
				[(model)]="userProp.value.value"
				[verified]="userProp.verified"
				(focused)="fieldFocused($event, userProp)"
				></label-select-pair>
				<label-input-pair
						*ngIf="!userProp.binary && userProp.display?.type !== 'checkbox'"
						label="{{ userProp.label }}"
						required="{{ userProp.requiredOnForm }}"
						disabled="{{ (!currentUserIsAdmin && !currentUserIsUserAdmin && userProp.used_for_login) ? 'true' : 'false' }}"
						pattern="{{ userProp.display?.pattern }}"
						patternErrorMessage="{{ userProp.getPatternErrorMessage(translate.currentLang) }}"
						[(model)]="userProp.value.value"
						(focused)="fieldFocused($event, userProp)"
						[reVerifyShow]="currentUserIsAdmin && !isModifySelf && user.id && userProp.name === 'primary_email' && !userProp.verified"
						(reVerify)="reVerify($event, userProp)"
						[verified]="userProp.verified">
				</label-input-pair>
		</div>
	</div>
	<div *ngIf="currentUserIsAdmin || currentUserIsUserAdmin" class="row">
		<h3 style="margin-left: 10px;">{{ "Roles" | translate }}</h3>
		<div *ngFor="let role of availableRoles" class="col-md-12 checkbox">
			<label class="label-for-pair" for="role-{{ role }}">
				<input id="role-{{ role }}"
				type="checkbox"
				[(ngModel)]="roles[role]" />
    		    <span>{{ role }}</span>
    		</label>

		</div>
	</div>

</form-page>
<!--  'cas/OAuth2/file/part-c-local': valid image url as pased to imageUrls -->
<modal [open]="alertVerify && !alertVerifyConfirm"
	   [heading]="'WARNING. Modification implications'"
	   [type]="'warning'"
	   (closed)="verifyAlertClosed()">
	<div [ngClass]="{ 'mb-1' : alertVerifySelectedProps.length > 0 }">
		{{ 'The modification of a verified field, results in a cancellation of the verification for the current field' | translate }}
		<span *ngIf="alertVerifySelectedProps.length > 0">{{ 'and for fields with associated verification.' | translate }}</span>
	</div>
	<div *ngIf="alertVerifySelectedProps.length > 0" class="mb-1">{{ 'The following fields verification will be also terminated if you modify:' | translate }}</div>
	<div *ngIf="alertVerifySelectedProps.length > 0">
		<strong class="badge badge-light mr-05" *ngFor="let invalProp of alertVerifySelectedProps"><i class="fa fa-shield badge-shield"></i>{{ invalProp.label }}</strong>
	</div>
	<div class="d-flex text-right mt-1">
		<button class="btn btn-danger" (click)="verifyAlertProceed()">{{ 'Yes, modify' | translate }}</button>
		<button class="btn btn-outline-secondary" (click)="verifyAlertClosed()">{{ 'general.cancel' | translate }}</button>
	</div>
</modal>
