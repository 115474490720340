<div class="form-group">
    <label class="label-for-pair {{large ? 'label-for-pair--large' : '' }}" for="{{label}}">
        <span style="font-weight:400" class="text-danger">{{required ? '* ' : '' }}</span>
        {{label | translate}}
        <span *ngIf="verified"><i class="fa fa-shield verified-field-icon"></i></span>
    </label>
    <div style="width:100%" class="input-group {{large ? 'input-group-lg ' : '' }}">
        <span *ngIf="icon" class="input-group-addon" [innerHTML]="icon"></span>
        <select *ngIf="!multiple" name="{{label}}"
                [(ngModel)]="model"
                (ngModelChange)="onChanges($event)"
                (focus)="onFocus($event)"
                [attr.required]="required ? true : null"
                [attr.disabled]="disabled ? true : null"
                id="{{label}}" class="form-control {{invalid ? 'input--errored' : '' }}">
            <option *ngFor="let option of options" [ngValue]="option.value">{{option.name | translate}}</option>
        </select>
        <select *ngIf="multiple" multiple name="{{label}}"
                [(ngModel)]="model"
                (ngModelChange)="onChanges($event)"
                [attr.required]="required ? true : null"
                [attr.disabled]="disabled ? true : null"
                id="{{label}}" class="form-control {{invalid ? 'input--errored' : '' }}">
            <option *ngFor="let option of multiSelectOptions" [ngValue]="option.value" [selected]="option.selected">{{option.name | translate}}</option>
        </select>
    </div>
</div>
