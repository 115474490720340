<form-page-layout title="{{'general.edit_settings' | translate}}"
				  [errors]="errors"
				  [successes]="successes"
				  icon="fa-pencil">

	<form (ngSubmit)="save()">

		<strong>{{errorMessage}}</strong>

		<div class="row">
			
			<label-input-pair label="settings.code"
							required
							class="col-md-6"
							[(model)]="settings.code"></label-input-pair>

			<label-input-pair label="settings.supportEmail"
							required
							class="col-md-6"
							[(model)]="settings.supportEmail"></label-input-pair>

			<label-input-pair label="settings.landingPageUri"
							required
							class="col-md-6"
							[(model)]="settings.landingPageUri"></label-input-pair>

			<app-file-upload
					[label]="logoLabel"
					[imageUrls]="logoImageUrls"
					[deleteUrl]="logoSaveUrl"
					[saveUrl]="logoSaveUrl"
					class="col-md-6"
					(onChange)="onLogoChange($event)"
			>
			</app-file-upload>

		</div>

		<form-page-button-block></form-page-button-block>
	</form>
</form-page-layout>
