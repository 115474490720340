<large-page-layout title="{{'header.configure_registration_form' | translate}}" icon="fa-sign-in">
	<button class="btn btn-w-md btn-line-success" (click)="addProperty()">
		<i class="fa fa-plus"></i>
		{{ 'configure_registration_form.add_field' | translate}}
	</button>
	<br>
	<br>
	<table class="table table-hover">
		<thead>
			<th>{{'configure_registration_form.fieldPlaceholder' | translate}}</th>
			<th>{{'configure_registration_form.fieldType' | translate}}</th>
			<th></th>
		</thead>
		<tbody [dragula]='"first-bag"' [dragulaModel]='propertyList'>
			<tr *ngFor="let formProperty of propertyList" [attr.data-id]="formProperty.id" style="cursor: move;">
				<td>
					<i class="fa fa fa-arrows"></i>&nbsp;&nbsp;{{ (formProperty.name === 'name' ? 'users.lastname' : getFieldDisplayedName(formProperty)) | translate }}</td>
				<td>{{ formProperty.display && formProperty.display.type }}</td>
				<td>	
					<div *ngIf="!reservedPropertyNames.includes(formProperty.name)" class="pull-right">
						<button class="btn btn-empty" [routerLink]="'/configure-registration-form/'+formProperty.id+'/edit'" >
							<i class="fa fa-pencil"></i>
						</button>
						<button *ngIf="!formProperty.un_deletable" class="btn btn-empty text-danger" (click)="propertiesToDelete=formProperty.id;lgModal.show()">
							<i class="fa fa-trash"></i>
						</button>
					</div>
				</td>
			</tr>
		</tbody>
	</table>
</large-page-layout>


<div bsModal #lgModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="lgModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title">{{'confirm.areyousure' | translate}}</h4>
      </div>
      <div class="modal-body">
        <div class="f-row">
          <button class="btn btn-w-md btn-line-success" (click)="lgModal.hide()"><i class="fa fa-undo"></i>
            {{'general.cancel' | translate}}
          </button>
          &nbsp;
          <button class="btn btn-w-md btn-line-danger" (click)="deleteProperty(propertiesToDelete);lgModal.hide()"><i
                  class="fa fa-trash"></i> {{'general.delete' | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
