<div>
	<div *ngIf="isUploading">
		<i style="color: #000000;" class="fa fa-spinner fa-spin"></i>
		<span>{{ progress }}</span>
	</div>
	<label *ngIf="!isUploading" class="upload-button file-input-wrapper btn btn-default form-control">
		<input type="file" class="hidden" ngFileSelect [options]="uploadOptions" (uploadOutput)="onUploadOutput($event)">
		<i class="glyphicon glyphicon-cloud-upload"></i>
		{{ 'upload_users.uplaod_users_list' | translate }}
	</label>
	
</div>
