<large-page-layout title="{{'dashboard.manage_partners' | translate}}" icon="fa-laptop">
  <button class="btn btn-w-md btn-line-success" [routerLink]="'/partner/create'"><i class="fa fa-plus"></i>
    {{'partner.add_partner' | translate}}
  </button>
  <br>
  <br>
  <table class="table table-hover">
    <thead>
    <th>{{'roles.name' | translate}}</th>
    <th></th>
    </thead>
    <tbody>
    <tr *ngFor="let partner of partners">
      <td>{{partner.name}}</td>
      <td>
        <div class="pull-right">
          <button class="btn btn-empty" [routerLink]="'/partner/'+partner.id+'/edit'"><i
                  class="fa fa-pencil"></i>
          </button>
          <button class="btn btn-empty text-danger" (click)="partnerToDelete=partner.id;lgModal.show()"><i
                  class="fa fa-trash"></i>
          </button>
        </div>
      </td>
    </tr>
    </tbody>
  </table>
</large-page-layout>


<div bsModal #lgModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="partnerToDelete='';lgModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title">{{'confirm.areyousure' | translate}}</h4>
      </div>
      <div class="modal-body">
        <div class="f-row">
          <button class="btn btn-w-md btn-line-success" (click)="partnerToDelete='';lgModal.hide()"><i class="fa fa-undo"></i>
            {{'general.cancel' | translate}}
          </button>
          &nbsp;
          <button class="btn btn-w-md btn-line-danger" (click)="delete(partnerToDelete);lgModal.hide()"><i
                  class="fa fa-trash"></i> {{'general.delete' | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
