<div class="panel panel-shadow panel-success mb-4">
    <div class="panel-heading collapsible-pane-header opened">
        <span>
          <span class="ml-1">{{ 'header.languages' | translate }} ({{ translate.langs.length }})</span>
        </span>
    </div>
    <div class="panel-body px-2 pb-4 pt-0">
        <table class="table table-hover list-table">
            <tbody>
                <tr *ngFor="let lan of translate.getLangs(); let last = last;" class="{{last ? 'last' : ''}}">
                    <td>{{lan | translate}}</td>
					<td style="width: 1px; text-align: center;">
						<button *ngIf="lan !== 'en' && lan !== translate.currentLang" class="btn btn-empty text-danger" (click)="removeLanguage(false, lan); lgModal.show()">
							<i class="fa fa-trash"></i>
						</button>
						<span *ngIf="lan === 'en' || lan === translate.currentLang"
							title="{{ 'English and currently used language cannot be removed' | translate }}" >
								<i style="color: #000;" class="fa fa-question-circle"></i></span></td>
                </tr>
            </tbody>
        </table>
		<div class="col-md-8">
        	<div class="col-md-6">
				<ngx-select-dropdown [(ngModel)]="selectedLang"
					[config]="{
						displayKey: 'text',
						search: true,
						placeholder: 'Search for translations' | translate,
						searchPlaceholder: 'general.search' | translate,
						noResultsFound: 'general.no_results' | translate
						}"
					[options]="allLanguages"></ngx-select-dropdown>
        	</div>
        	<div class="col-md-2" style="padding-left: 10px;">
				<button (click)="addLanguage()" class="btn btn-w-md btn-line-success" >
          <i class="fa fa-plus"></i> {{ 'Add' | translate }}
				</button>
        	</div>
		</div>
    </div>
</div>
<div bsModal #lgModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="lgModal.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 class="modal-title">{{'confirm.areyousure' | translate}}</h4>
      </div>
      <div class="modal-body">
        <div class="f-row">
          <button class="btn btn-w-md btn-line-success" (click)="lgModal.hide()"><i class="fa fa-undo"></i>
            {{'general.cancel' | translate}}
          </button>
          &nbsp;
          <button class="btn btn-w-md btn-line-danger" (click)="removeLanguage(true);lgModal.hide()"><i
                  class="fa fa-trash"></i> {{'general.delete' | translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>