<form-page-layout title="{{'general.create' | translate}} {{'partners.partner' | translate}}"
                  [errors]="errors"
                  [successes]="successes"
                  icon="fa-plus">
  <form (ngSubmit)="save()">

    <strong>{{errorMessage}}</strong>

    <div class="row">

      <label-input-pair label="roles.name"
                        required
                        class="col-md-6"
                        [(model)]="partner.name"></label-input-pair>

      <label-input-pair label="oaclient.redirect_uri"
                        required
                        class="col-md-6"
                        [(model)]="partner.redirect_url"></label-input-pair>

      <label-input-pair label="partner.order"
                        required
                        class="col-md-6"
                        [(model)]="partner.order"></label-input-pair>

      <label-input-pair label="partner.logo_height"
                        required
                        class="col-md-6"
                        [(model)]="partner.logo_height"></label-input-pair>

      <label-input-pair label="partner.logo_width"
                        required
                        class="col-md-6"
                        [(model)]="partner.logo_width"></label-input-pair>

      <app-file-upload
              [base64mode]="true"
              [base64]="base64"
              class="col-md-6"
              [allowedFileTypes]="allowedFileTypes"
      >
      </app-file-upload>

  </div>

    <form-page-button-block></form-page-button-block>
  </form>
</form-page-layout>
