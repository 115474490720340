<page-layout>
    <div class="col-md-12 f-container f-wrap-wrap f-stack-vertical-mobile justify-content-space-around">

		<dashboard-card class="dashboard-tile-wrapper f-column-30-large-100-mobile"
                        title="dashboard.personal_user_info"
                        description="dashboard.personal_user_info_description"
                        button-text="general.manage"
                        link="/user-personal"
                        icon="fa-user">
        </dashboard-card>

		<dashboard-card class="dashboard-tile-wrapper f-column-30-large-100-mobile"
                        *ngIf="currentUserIsAdmin || currentUserIsUserAdmin"
                        title="dashboard.manage_users"
                        description="dashboard.manage_users_description"
                        button-text="general.manage"
                        link="/users"
                        icon="fa-users">
		</dashboard-card>

		<dashboard-card
						class="dashboard-tile-wrapper f-column-30-large-100-mobile"
						*ngIf="currentUserIsAdmin"
						title="header.configure_registration_form"
						description="dashboard.configure_registration_form_description"
						button-text="general.manage"
						link="/configure-registration-form"
						icon="fa-sign-in">
		</dashboard-card>

		<dashboard-card class="dashboard-tile-wrapper f-column-30-large-100-mobile"
                        *ngIf="currentUserIsAdmin"
                        title="dashboard.manage_oaclients"
                        description="dashboard.manage_oaclients_description"
                        button-text="general.manage"
                        link="/oaclients"
                        icon="fa-laptop">
		</dashboard-card>

		<dashboard-card class="dashboard-tile-wrapper f-column-30-large-100-mobile"
                        *ngIf="currentUserIsAdmin"
                        title="dashboard.translations"
                        description="dashboard.translations_description"
                        button-text="general.manage"
                        link="/translations"
                        icon="fa-language">
		</dashboard-card>

		<dashboard-card class="dashboard-tile-wrapper f-column-30-large-100-mobile"
						*ngIf="currentUserIsAdmin"
						title="dashboard.manage_settings"
						description="dashboard.manage_settings_description"
						button-text="general.manage"
						link="/settings"
						icon="fa-cogs">
		</dashboard-card>
		<dashboard-card class="dashboard-tile-wrapper f-column-30-large-100-mobile"
						*ngIf="currentUserIsAdmin"
						title="dashboard.manage_partners"
						description="dashboard.manage_partners_description"
						button-text="general.manage"
						link="/partners"
						icon="fa-building">
		</dashboard-card>
    </div>
</page-layout>
