<page-layout>
  <div class="col-md-12">
    <panel [goBackLink]="goBackLink" [showGoBack]="true" [icon]="icon" title="{{title | translate}}">
      <alert-box [errors]="errors" [successes]="successes"></alert-box>

        <ng-content></ng-content>

    </panel>
  </div>
</page-layout>

