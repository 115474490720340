<div class="panel panel-default">
    <div class="panel-heading" style="display: flex;justify-content: start; align-items: center;">
		<span class="fa-stack fa-lg">
			<i class="fa fa-circle fa-stack-2x icon-background-color"></i>
			<i class="fa {{icon}} fa-stack-1x"></i>
		</span>
		<span>{{title}}</span>
    </div>
    <div class="panel-body">
        <ng-content></ng-content>
    </div>
</div>