<section class="top-header" id="header">
	<header class="clearfix">
		<!--<a  href="" class="toggle-min" (click)="false">-->
			<!--<i class="fa fa-bars"></i>-->
		<!--</a>-->
		<div class="logo">
			<a routerLink="/dashboard" title="{{'header.goToDashboard' | translate}}">
				<img style="max-height: 42px;" [src]="casBaseUrl + 'configuration/settings/cas-logo'"/>
			</a>
		</div>
		<div class="top-nav">
			<ul class="nav-right pull-right list-unstyled">
				<li *ngIf="serviceId != undefined">
					<a routerLink="/services/{{serviceId}}/preview2">
						<i class="fa fa-eye icon-background-color"></i>
						<span>{{ 'header.preview' | translate }}</span>
					</a>
				</li>
				<li class="dropdown nav-profile lang-dropdown" dropdown>
					<span dropdownToggle class="dropdown-toggle cursor-pointer">
						{{translate.currentLang | translate}} <span class="caret"></span>
					</span>
					<ul *dropdownMenu class="dropdown-menu">
						<li  role="menuitem" *ngFor="let lang of translate.getLangs()" >
							<a href="#" (click)="userLangResolver.changeLang(lang); false;">{{ lang | translate }}</a>
						</li>

					</ul>
				</li>
				<li class="dropdown nav-profile" dropdown>
					<span dropdownToggle class="dropdown-toggle cursor-pointer hide-320">
							<span class="fa-stack">
								<i class="fa fa-circle fa-stack-2x icon-background-color"></i>
								<i class="fa fa-user fa-stack-1x"></i>
							</span>
						<!-- <i class="fa fa-user icon-background-color"></i> -->
							<span>{{getName()}}</span>
						<span style="margin-left:5px;padding-bottom: 2px"
							  *ngIf="showNotVerifiedMessage"
							  class="c-badge c-badge--error hidden-xs">
							{{ verificationMessage | translate }}
						</span>
					</span>
					<ul class="dropdown-menu" *dropdownMenu>
						<li role="menuitem">
							<a (click)="onLogout();">
								<i class="fa fa-power-off icon-background-color"></i>
								<span>{{ 'header.log_out' | translate }}</span>
							</a>
						</li>
					</ul>
				</li>
				<li style="cursor: pointer" class="dropdown text-normal nav-profile pull-right">
					<a (click)="onLogout();">
						<i class="fa fa-power-off" style="color:#000"></i>
					</a>
				</li>
			</ul>
		</div>
	</header>
</section>
