<form-page-layout title="{{'general.create' | translate}} {{'oaclients.oaclient' | translate}}"
                  [errors]="errors"
                  [successes]="successes"
                  icon="fa-plus">
  <form (ngSubmit)="save()">

    <strong>{{errorMessage}}</strong>

    <div class="row">

      <label-input-pair label="{{'oaclient.id' | translate}}"
                        required
                        class="col-md-6"
                        icon="<i class='fa fa-laptop'></i>"
                        [(model)]="oaclient.id"></label-input-pair>

      <label-input-pair label="{{'oaclient.redirect_uri' | translate}}"
                        required
                        class="col-md-6"
                        [(model)]="oaclient.redirect_uri"></label-input-pair>

      <label-input-pair type="password" label="{{'oaclient.secret' | translate}}"
                        required
                        class="col-md-6"
                        icon="<i class='fa fa-lock'></i>"
                        [(model)]="oaclient.secret"></label-input-pair>

      <label-select-pair label="{{'Disabled' | translate}}" class="col-md-6"
                        [(model)]="oaclient.disabled"></label-select-pair>

      <label-select-pair label="{{'oaclient.trusted' | translate}}" class="col-md-6"
                        [(model)]="oaclient.trusted"></label-select-pair>

      <label-input-pair label="{{'Description' | translate}}" class="col-md-6"
                        [(model)]="oaclient.description"></label-input-pair>

    </div>


    <form-page-button-block></form-page-button-block>
  </form>
</form-page-layout>