<app-header></app-header>
<div class="body-special">
    <app-wrapper>
        <div *ngIf="!currentUserIsVerified && !customAuthorizeMessagesArePresent()" class="alert alert-danger" role="alert">
            <div>{{'Your email is not verified. Please verify your email by pressing' | translate}}
                <a style="cursor: pointer" (click)="verify()">{{'here' | translate}}</a></div>
        </div>
        <div *ngIf="!currentUserIsVerified && customAuthorizeMessagesArePresent()" role="alert">
            <div class="alert alert-danger" *ngFor="let msg of customAuthorizeMessages()">{{msg}}</div>
        </div>
        <div *ngIf="pressedVerification" class="alert alert-success" role="success">
            <div>{{'Thank you! Please check your email to submit verification' | translate}}
                <a style="cursor: pointer" (click)="closeVerificationAlert()">({{'Dismiss' | translate}})</a>
                <button (click)="closeVerificationAlert()" type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            </div>
        </div>
        <div class="row card-empty">
            <ng-content></ng-content>
        </div>
    </app-wrapper>
</div>
